.footer {
  background-color: #333;
  color: #fff;
  padding: 40px 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-left {
  flex: 1;
}

.footer-left h3 {
  font-size: 20px;
}

.footer-left p {
  margin: 8px 0;
}

.footer-right {
  flex: 1;
  text-align: right;
}

.social-icons {
  list-style: none;
  padding: 0;
}

.social-icons li {
  display: inline-block;
  margin-right: 10px;
}

.social-icons li:last-child {
  margin-right: 0;
}

.social-icons a {
  color: #fff;
  font-size: 24px;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
}
